<template>
	<div>
		<el-steps :active="active" align-center finish-status="wait">
		<el-step v-for="(item,index) in steps" :key='index'>
			<template slot="icon"><img :src='active>index?item.aicon:item.icon' class="stepicon" /></template>
			<template slot="title"><img :src='active>index?item.atitle:item.title' class="steptitle" /></template>
		</el-step>
	</el-steps>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				steps: [ //步骤数据
					{
						"title": require('../../../assets/slices/step11.png'),
						"atitle": require('../../../assets/slices/step11.png'),
						"icon": require('../../../assets/slices/step1.png'),
						"aicon": require('../../../assets/slices/step1.png')
					}, {
						"title": require('../../../assets/slices/step22.png'),
						"atitle": require('../../../assets/slices/step22.png'),
						"icon": require('../../../assets/slices/step2.png'),
						"aicon": require('../../../assets/slices/step2.png')
					}, {
						"title": require('../../../assets/slices/step3333.png'),
						"atitle": require('../../../assets/slices/step33.png'),
						"icon": require('../../../assets/slices/step333.png'),
						"aicon": require('../../../assets/slices/step3.png')
					}, {
						"title": require('../../../assets/slices/step4444.png'),
						"atitle": require('../../../assets/slices/step44.png'),
						"icon": require('../../../assets/slices/step444.png'),
						"aicon": require('../../../assets/slices/step4.png')
					}
				]
			}
		},
		props:["active"],
		mounted () {
		    
		 }		
	}
</script>

<style>
	.stepicon {
		width: 70px;
	}
.el-step.is-horizontal .el-step__line{
    border-top: 2px dashed #ff1918;
    border-radius: 50%;
    height: 0;
    background-color: transparent;
    
}
</style>